.landing_title {
  @apply flex flex-col sm:text-[30px] xl:text-[45px] 2xl:text-[65px] font-bold leading-130 mt-0;
  letter-spacing: -0.5px;

  @media screen and (min-width: 1536px) and (max-width: 1800px) {
    @apply text-[48px];
  }
}

.landing_description {
  @apply font-normal sm:text-body1 xl:text-base 2xl:text-[18px] leading-[27px] mt-[20px] mb-8 xl:mb-[65px];
}

.landing_card {
  @apply flex flex-col justify-center sm:pr-[10%] lg:pr-[15%] 2xl:pr-[120px] w-full;

  @media screen and (min-width: 1121px) and (max-width: 1199px) {
    @apply pr-[23%];
  }
}

.landing_box {
  @apply grid grid-cols-2 sm:gap-8 lg:gap-10 xl:gap-[60px] h-full justify-items-end w-full pt-[70px] pb-[56px] 2xl:pt-[80px];

  @media screen and (min-width: 1800px) {
    @apply pb-[70px];
  }
}

.landing_section {
  @apply relative bg-[url('/images/landingbg.png')] h-screen bg-cover bg-center bg-no-repeat;
}

.landing_login_text {
  @apply mt-[25px] text-body1 lg:text-base 2xl:text-[18px] font-normal tracking-wide;
}
